import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import FullBatchActions from './FullBatchActions';
import ShortBatchActions from './ShortBatchActions';
import SelectedTablesTitle from './SelectedTablesTitle';

const BatchActionsContainer = ({
  selectedIds,
  totalItems,
  selectedTables,
  displaySelected,
  onToggleDisplaySelected,
  onArchive,
  onDelete,
  onClear,
  minimize
}) => {
  const handleArchive = useCallback(() => {
    onArchive();
  }, [onArchive]);

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const handleClear = useCallback(() => {
    onClear();
  }, [onClear]);

  const BatchActionsTag = minimize ? ShortBatchActions : FullBatchActions;

  return (
    <Box width="100%" minHeight="30px">
      {selectedIds.length ?
        <BatchActionsTag
          totalSelected={selectedIds.length}
          displaySelected={displaySelected}
          onToggleDisplaySelected={onToggleDisplaySelected}
          onArchive={handleArchive}
          onDelete={handleDelete}
          onClear={handleClear}
        /> :
        <SelectedTablesTitle data={selectedTables} totalItems={totalItems} />
      }
    </Box>
  );
};

BatchActionsContainer.propTypes = {
  selectedIds: PT.array.isRequired,
  totalItems: PT.number,
  selectedTables: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string,
    path: PT.string
  })),
  minimize: PT.bool,
  displaySelected: PT.bool,
  onToggleDisplaySelected: PT.func,
  onShare: PT.func,
  onArchive: PT.func,
  onDelete: PT.func,
  onClear: PT.func,
};

export default BatchActionsContainer;
