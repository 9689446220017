import { PARAMETER_VALUE_TYPES } from '../../constants';
import toString from 'lodash/toString';

const getParameterInitValueMap = data => {
  switch (data.type) {
    case PARAMETER_VALUE_TYPES.QUANTITY: {
      return {
        min: toString(data?.quantityMin),
        max: toString(data?.quantityMax)
      };
    }
    case PARAMETER_VALUE_TYPES.BOOLEAN: {
      return data?.boolean || [];
    }
    case PARAMETER_VALUE_TYPES.LINK: {
      return data?.linkItemIds ?? [];
    }
    case PARAMETER_VALUE_TYPES.TEXT: {
      return data.textValues ?? [];
    }
    default:
      return data.textValues ?? [];
  }
};

const mapInputToStateParamsValues = features => data => {
  const type = features[data.featureId].valueType;

  return {
    type,
    featureId: data.featureId,
    method: data.include ? 'INCLUDE' : 'EXCLUDE',
    value: getParameterInitValueMap({ type, ...data })
  };
};

export default mapInputToStateParamsValues;
