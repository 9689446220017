import styled from 'styled-components';

import Drawer from '@mui/material/Drawer';

export const DrawerStyled = styled(Drawer).withConfig({
  shouldForwardProp: prop => !['panelWidth'].includes(prop)
})`
  width: ${({ panelWidth = 300 }) => `${panelWidth}px`};
  flex-shrink: 0;

    & .MuiDrawer-paper {
      width: ${({ panelWidth = 300 }) => `${panelWidth}px`};
      box-sizing: border-box;
      margin-top: 64px;
      z-index: 1;
      height: calc(100% - 64px);
      padding: 16px 0;
      background-color: #F9F9F9;
      overflow-x: hidden;
    }
`;
